.results-section {
  background-color: var(--white);
  margin: 1rem 0 8rem;
  padding-bottom: 2rem;
}

.results-section .sub-heading {
  padding: 2rem 0;
  max-width: 85%;
  margin: auto;
}

.results-section .sub-heading h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 2.7rem;
  color: var(--dark-blue);
  margin-bottom: 1rem;
  padding: 0.7rem 0;
  letter-spacing: 0.2rem;
}

.results-section .sub-heading p {
  font-size: 1.3rem;
  color: var(--dark-blue);
  text-align: justify;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.results-section .results-section-inner {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  margin: 0 5rem;
}

.results-section .results-section-inner .info {
  flex: 0 0 50%;
  max-width: 50%;
}

.results-section .results-section-inner .second {
  margin-top: 3rem;
}
.results-section .results-section-inner .third {
  margin-top: 3rem;
  margin-left: 10rem;
}
.results-section .results-section-inner .third-video {
  margin-left: 3rem;
}

.results-section .results-section-inner .info h3 {
  color: var(--medium-blue);
  font-size: 1.5rem;
  margin: 1rem 0;
}

.results-section .results-section-inner .info p {
  font-size: 1.3rem;
  line-height: 1.5;
  margin-bottom: 3rem;
  text-align: justify;
}
.results-section .btn {
  width: 20vw;
}

@media screen and (max-width: 950px) {
  .results-section {
    margin-bottom: 25rem;
  }
  .results-section .results-section-inner {
    margin: 0 3rem;
  }
  .results-section .results-section-inner .info {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media screen and (max-width: 880px) {
  .results-section .sub-heading h2 {
    font-size: 2rem;
  }
  .results-section .btn {
    width: 30vw;
  }
  .results-section .sub-heading p {
    margin-bottom: 1rem;
  }
  .results-section .results-section-inner .info p {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 820px) {
  .results-section {
    margin-bottom: 20rem;
  }
  .results-section .sub-heading h2 {
    font-size: 1.8rem;
  }
  .results-section .sub-heading p {
    margin-bottom: 0;
  }
  .results-section .results-section-inner .info p {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 800px) {
  .results-section {
    margin-bottom: 20rem;
  }
  .results-section .results-section-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .results-section .results-section-inner:nth-child(3) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .results-section .results-section-inner .video {
    height: 400px;
    width: 400px;
  }
  .results-section .results-section-inner .video1 {
    margin-left: 2rem;
  }
  .results-section .results-section-inner .info {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .results-section .results-section-inner .third {
    margin-top: 1rem;
    margin-left: 0;
  }
  .results-section .results-section-inner .third-video {
    margin-left: 0;
  }
  .results-section .results-section-inner .second {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 770px) {
  .results-section {
    margin-bottom: 20rem;
  }
}
@media screen and (max-width: 730px) {
  .results-section {
    margin-bottom: 15rem;
  }
  .results-section .results-section-inner {
    margin: 0 2rem;
  }
}
@media screen and (max-width: 600px) {
  .results-section .sub-heading h2 {
    letter-spacing: 0;
  }
  .results-section .results-section-inner .video {
    height: 300px;
    width: 400px;
  }
  .results-section .btn {
    width: 40vw;
  }
}
@media screen and (max-width: 520px) {
  .results-section .sub-heading h2 {
    font-size: 1.2rem;
    letter-spacing: 0;
  }

  .results-section .sub-heading {
    padding-top: 2rem;
  }
  .results-section {
    margin-bottom: 10rem;
  }
  .results-section .sub-heading p {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
  .results-section .results-section-inner .info p {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
  .results-section .btn {
    width: 40vw;
  }
}

@media screen and (max-width: 420px) {
  .results-section .results-section-inner .video {
    height: 300px;
    width: 350px;
  }
}
@media screen and (max-width: 350px) {
  .results-section .results-section-inner .video {
    height: 300px;
    width: 300px;
  }
}
