.contact-form .contact-card {
  margin-top: 3rem;
}

.contact-form .contact-card .sub-heading {
  max-width: 85%;
  margin: auto;
}
.contact-form .contact-card .sub-heading h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 2.7rem;
  color: var(--dark-blue);
  margin-bottom: 1rem;
  padding: 0.7rem 0;
  letter-spacing: 0.2rem;
}
.contact-form .contact-card .contact-card-inner {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 5rem;
}

.contact-form .contact-card .contact-card-inner .video {
  width: 300px;
}

.contact-form .send-btn {
  text-align: center;
}

.contact-form .submit-btn {
  color: var(--dark-blue);
  border: 0.1rem solid var(--dark-blue);
  padding: 1rem;
  border-radius: 0.6rem;
  text-align: center;
  font-size: 1.4rem;
  text-decoration: none;
  position: relative;
  z-index: 3;
  background-color: transparent;
  cursor: pointer;
}

.contact-form .submit-btn:hover {
  color: var(--white);
}

.contact-form .submit-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(45deg, var(--dark-blue), var(--medium-blue));
  z-index: -1;
  border-radius: 0.4rem;
  transition: all 0.5s;
}

.contact-form .submit-btn:hover::before {
  width: 100%;
  height: 100%;
}

.contact-card-info .input-group {
  margin: 0 0 1.5rem;
  /* border-radius: 1.8rem; */
}

.contact-card-info .input-group .input-control {
  height: 3rem;
  display: block;
  width: 100%;
  border-radius: 0.7rem;
  border: none;
  outline: none;
  background-color: rgb(241, 241, 245);
  font-size: 1rem;
  padding: 0 1rem;
  color: var(--medium-blue);
}

.contact-card-info .input-group .textarea {
  height: 140px;
  padding-top: 1rem;
  resize: none;
}

.contact-card-info .input-group textarea::placeholder,
.contact-card-info .input-group input::placeholder {
  font-family: "Josefin Sans", sans-serif;
}
@media screen and (max-width: 1250px) {
  .contact-form .contact-card {
    max-width: 90%;
    padding: 1rem;
  }
}

@media screen and (max-width: 950px) {
  .contact-form .contact-card {
    max-width: 100%;
    padding: 1rem;
  }
}

@media screen and (max-width: 880px) {
  .contact-form .contact-card .sub-heading h2 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 820px) {
  .contact-form .contact-card .sub-heading h2 {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 800px) {
  .contact-form .video {
    display: none;
  }
  .contact-form .contact-card p {
    margin-bottom: 2rem;
  }

  .contact-form .inner {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .contact-form .sub-heading {
    padding-bottom: 0;
  }
  .contact-form .contact-card {
    margin-top: 2rem;
    padding-top: 0;
  }
  .contact-form .contact-card .contact-card-inner {
    margin: 0 3rem;
  }
}
@media screen and (max-width: 730px) {
  .contact-form .contact-card {
    max-width: 100%;
  }
  .contact-form .contact-card .contact-card-inner {
    margin: 0 2rem;
  }
}
@media screen and (max-width: 600px) {
  .contact-form .contact-card .sub-heading h2 {
    letter-spacing: 0;
  }
  .contact-form .contact-card {
    margin-top: 1rem;
    padding-top: 0;
  }
}
@media screen and (max-width: 520px) {
  .contact-form .contact-card p {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
  .contact-form .contact-card .sub-heading h2 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 480px) {
  .contact-form .submit-btn {
    padding: 1rem;
    border-radius: 0.6rem;
    text-align: center;
    font-size: 1rem;
    text-decoration: none;
    position: relative;
    z-index: 3;
  }
}
