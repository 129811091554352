.scrollbutton {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  outline: none;
  border: 2px solid var(--white);
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;
  text-decoration: none;
  position: fixed;
  z-index: 10;
  right: 4%;
  bottom: 11.5%;
  background: linear-gradient(45deg, var(--dark-blue), var(--medium-blue));
  cursor: pointer;
  color: var(--white);
  transition: 0.3s all ease;
  visibility: hidden;
}

.scrollbutton:hover {
  color: var(--medium-blue);
  border: 2px solid var(--medium-blue);
  background: transparent;
}

@media screen and (max-width: 1100px) {
  .scrollbutton {
    bottom: 13%;
  }
}
@media screen and (max-width: 980px) {
}

@media screen and (max-width: 850px) {
  .scrollbutton {
    bottom: 11%;
  }
}
@media screen and (max-width: 550px) {
  .scrollbutton {
    bottom: 16%;
  }
}

@media screen and (max-width: 500px) {
  .scrollbutton {
    bottom: 15%;
  }
}
@media screen and (max-width: 420px) {
  .scrollbutton {
    bottom: 5%;
  }
}
