.animation-section {
  height: 100%;
  width: 100%;
  position: fixed;
  background: radial-gradient(#333, #000);
  top: 0;
  left: 0;
  z-index: -100;
}
.animation-section .leaf {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.animation-section .leaf div {
  position: absolute;
  display: block;
}
.animation-section .leaf div:nth-child(1) {
  left: 6%;
  animation: fall 10s linear infinite;
  animation-delay: -1s;
}
.animation-section .leaf div:nth-child(2) {
  left: 12%;
  animation: fall 5s linear infinite;
  animation-delay: -2s;
}
.animation-section .leaf div:nth-child(3) {
  left: 18%;
  animation: fall 10s linear infinite;
  animation-delay: -3s;
}
.animation-section .leaf div:nth-child(4) {
  left: 24%;
  animation: fall 5s linear infinite;
  animation-delay: -4s;
}
.animation-section .leaf div:nth-child(5) {
  left: 30%;
  animation: fall 10s linear infinite;
  animation-delay: -5s;
}
.animation-section .leaf div:nth-child(6) {
  left: 36%;
  animation: fall 5s linear infinite;
  animation-delay: -6s;
}
.animation-section .leaf div:nth-child(7) {
  left: 42%;
  animation: fall 10s linear infinite;
  animation-delay: -7s;
}
.animation-section .leaf div:nth-child(8) {
  left: 48%;
  animation: fall 5s linear infinite;
  animation-delay: -8s;
}
.animation-section .leaf div:nth-child(9) {
  left: 54%;
  animation: fall 10s linear infinite;
  animation-delay: -9s;
}
.animation-section .leaf div:nth-child(10) {
  left: 60%;
  animation: fall 5s linear infinite;
  animation-delay: -10s;
}
.animation-section .leaf div:nth-child(11) {
  left: 66%;
  animation: fall 10s linear infinite;
  animation-delay: -11s;
}
.animation-section .leaf div:nth-child(12) {
  left: 72%;
  animation: fall 5s linear infinite;
  animation-delay: -12s;
}
.animation-section .leaf div:nth-child(13) {
  left: 78%;
  animation: fall 10s linear infinite;
  animation-delay: -13s;
}
.animation-section .leaf div:nth-child(14) {
  left: 84%;
  animation: fall 5s linear infinite;
  animation-delay: -14s;
}
.animation-section .leaf div:nth-child(15) {
  left: 90%;
  animation: fall 10s linear infinite;
  animation-delay: -15s;
}

@keyframes fall {
  0% {
    opacity: 1;
    top: -10%;
    transform: translateX (20px) rotate(0deg);
  }
  20% {
    opacity: 0.8;
    transform: translateX (-20px) rotate(45deg);
  }
  40% {
    transform: translateX (-20px) rotate(90deg);
  }
  60% {
    transform: translateX (-20px) rotate(135deg);
  }
  80% {
    transform: translateX (-20px) rotate(180deg);
  }
  100% {
    top: 110%;
    transform: translateX (-20px) rotate(225deg);
  }
}
.animation-section .leaf1 {
  transform: rotateX(90deg);
}
.animation-section .leaf3 {
  transform: rotateX(90deg);
}
