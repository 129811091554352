.map {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.map iframe {
  width: 600px;
  height: 400px;
}

@media screen and (max-width: 1150px) {
  .map iframe {
    width: 500px;
    height: 300px;
  }
}
@media screen and (max-width: 1020px) {
  .map iframe {
    width: 450px;
    height: 300px;
  }
}

@media screen and (max-width: 950px) {
  .map iframe {
    width: 650px;
    height: 300px;
  }
}
@media screen and (max-width: 870px) {
  .map iframe {
    width: 550px;
    height: 300px;
  }
}

@media screen and (max-width: 730px) {
  .map iframe {
    width: 450px;
    height: 300px;
  }
}

@media screen and (max-width: 520px) {
  .map iframe {
    width: 400px;
    height: 250px;
  }
}
@media screen and (max-width: 460px) {
  .map iframe {
    width: 300px;
    height: 200px;
  }
}

@media screen and (max-width: 420px) {
}
