.faq-section {
  background-color: var(--white);
  margin: 1rem 0 8rem;
}

.faq-section .faq-section-inner {
  margin: 0 5rem;
}

.faq-section .faq-section-inner .details {
  text-align: center;
}

.faq-section .faq-section-inner .details p {
  font-size: 1.3rem;
  line-height: 1.5rem;
  padding: 2.5rem;
  text-align: justify;
}

.faq-section .faq-section-inner .sub-heading {
  padding: 2rem 0;
  margin: auto;
  text-align: center;
}

.faq-section .faq-section-inner .sub-heading h2 {
  text-transform: uppercase;
  font-size: 2.7rem;
  color: var(--dark-blue);
  margin-bottom: 1rem;
  padding: 0.7rem 0;
  letter-spacing: 0.2rem;
}

.faq-section .faq-section-inner .sub-heading h3 {
  font-size: 1.5rem;
  text-align: justify;
  line-height: 1.5;
  margin-bottom: 2rem;
  color: var(--medium-blue);
  text-transform: uppercase;
}

.faq-section .faq-section-inner .input-group {
  margin: 0 0 1.5rem;
  position: relative;
  /* border-radius: 1.8rem; */
}

.faq-section .faq-section-inner .input-group .fas {
  position: absolute;
  left: 1rem;
  top: 1rem;
  font-size: 1rem;
  color: var(--medium-blue);
}

.faq-section .faq-section-inner .input-group .input-control {
  height: 3rem;
  display: block;
  width: 100%;
  border-radius: 0.7rem;
  border: 1px solid var(--medium-blue);
  background-color: rgb(241, 241, 245);
  font-size: 1.1rem;
  padding: 0 3rem;
  color: var(--medium-blue);
}

.faq-section .faq-section-inner .main-faq {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem 2rem;
}

.faq-section .faq-section-inner .main-faq .video {
  flex: 0 0 30%;
  max-width: 30%;
}
.faq-section .faq-section-inner .main-faq .main-faq-inner {
  flex: 0 0 60%;
  max-width: 60%;
}

.faq-section .faq-section-inner .main-faq .main-faq-inner .questions {
  overflow-x: scroll;
  max-height: 400px;
  height: auto;
  scrollbar-width: auto;
  transition: all 0.3s ease-in-out;
}

.faq-section
  .faq-section-inner
  .main-faq
  .main-faq-inner
  .questions
  .questions-inner {
  transition: all 0.3s ease-in-out;
}

.faq-section
  .faq-section-inner
  .main-faq
  .main-faq-inner
  .questions
  .questions-inner
  .icon {
  transition: 0.3s ease;
}

.faq-section
  .faq-section-inner
  .main-faq
  .main-faq-inner
  .questions::-webkit-scrollbar {
  display: block;
}

.faq-section .faq-section-inner .main-faq .main-faq-inner .questions .question {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--medium-blue);
  font-size: 1.3rem;
  line-height: 1.5;
  cursor: pointer;
}

.faq-section
  .faq-section-inner
  .main-faq
  .main-faq-inner
  .questions
  .question
  .icon {
  font-size: 1.1rem;
}

.faq-section .faq-section-inner .main-faq .main-faq-inner .questions .answer {
  padding: 0 1rem;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: 0.3s ease;
  font-size: 1.2rem;
  line-height: 1.5;
}
.faq-section
  .faq-section-inner
  .main-faq
  .main-faq-inner
  .questions
  .answer
  .link {
  color: var(--medium-blue);
}

.faq-section
  .faq-section-inner
  .main-faq
  .main-faq-inner
  .questions
  .questions-inner.active
  .icon {
  transform: rotate(180deg);
}

.faq-section
  .faq-section-inner
  .main-faq
  .main-faq-inner
  .questions
  .questions-inner.active
  .answer {
  height: auto;
  opacity: 1;
  padding-bottom: 0.5rem;
}

.faq-section .faq-section-inner .btn {
  width: 15vw;
  margin: auto;
}

@media screen and (max-width: 1250px) {
  .faq-section .faq-section-inner {
    max-width: 90%;
  }
}

@media screen and (max-width: 1100px) {
  .faq-section .faq-section-inner .details p {
    padding: 2rem;
  }
}
@media screen and (max-width: 950px) {
  .faq-section .faq-section-inner {
    max-width: 100%;
    padding: 0 1rem 1rem;
  }
  .faq-section .faq-section-inner .btn {
    width: 20vw;
  }
  .faq-section .faq-section-inner .details p {
    padding: 1.5rem 1rem;
  }
}

@media screen and (max-width: 880px) {
  .faq-section .faq-section-inner .sub-heading h2 {
    font-size: 2rem;
  }
  .faq-section .faq-section-inner .sub-heading h3 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  .faq-section .faq-section-inner .details p {
    padding: 1rem 0;
  }
}
@media screen and (max-width: 820px) {
  .faq-section .faq-section-inner .sub-heading h2 {
    font-size: 1.8rem;
  }
  .faq-section .faq-section-inner .sub-heading h3 {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 800px) {
  .faq-section .faq-section-inner .main-faq {
    max-width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .faq-section .inner {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .faq-section .faq-section-inner .sub-heading {
    padding-bottom: 0;
  }
  .faq-section .faq-section-inner {
    margin: 0 2rem;
  }
  .faq-section .faq-section-inner .main-faq .video {
    flex: 0 0 100%;
    max-width: 100%;
    height: 300px;
    width: 300px;
    margin-bottom: 1rem;
  }
  .faq-section .faq-section-inner .main-faq .main-faq-inner {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 730px) {
  .faq-section .faq-section-inner h3 {
    font-size: 1.5rem;
  }
  .faq-section .faq-section-inner .sub-heading {
    padding-top: 0;
  }
  .faq-section .faq-section-inner {
    max-width: 100%;
    padding: 1rem;
  }
  .faq-section .faq-section-inner .btn {
    width: 25vw;
  }
}
@media screen and (max-width: 600px) {
  .faq-section .faq-section-inner h3 {
    letter-spacing: 0;
  }
  .faq-section .faq-section-inner .main-faq .video {
    flex: 0 0 100%;
    max-width: 100%;
    height: 200px;
    width: 200px;
  }
  .faq-section .faq-section-inner .sub-heading h2 {
    letter-spacing: 0;
  }
  .faq-section .faq-section-inner .sub-heading h3 {
    letter-spacing: 0;
  }
  .faq-section .faq-section-inner .btn {
    width: 30vw;
  }
}
@media screen and (max-width: 520px) {
  .faq-section .faq-section-inner h3 {
    font-size: 1.2rem;
    letter-spacing: 0;
  }

  .faq-section .faq-section-inner .details p {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
  .faq-section
    .faq-section-inner
    .main-faq
    .main-faq-inner
    .questions
    .question {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
  .faq-section .faq-section-inner .sub-heading h2 {
    font-size: 1.2rem;
  }
  .faq-section .faq-section-inner .sub-heading h3 {
    font-size: 1.1rem;
  }
  .faq-section .faq-section-inner .main-faq .main-faq-inner .questions .answer {
    font-size: 1rem;
    line-height: 1.2;
  }
  .faq-section .faq-section-inner .btn {
    width: 35vw;
  }
  .faq-section
    .faq-section-inner
    .main-faq
    .main-faq-inner
    .questions
    .question {
    padding: 0.5rem;
  }
}
@media screen and (max-width: 480px) {
  .faq-section .faq-section-inner .btn {
    width: 25vw;
  }
}

@media screen and (max-width: 420px) {
  .faq-section .faq-section-inner .main-faq .video {
    flex: 0 0 100%;
    max-width: 100%;
    height: 150px;
    width: 150px;
  }
  .faq-section .faq-section-inner .sub-heading h3 {
    font-size: 1rem;
  }
  .faq-section .faq-section-inner .btn {
    width: 30vw;
  }
}
@media screen and (max-width: 350px) {
  .faq-section .faq-section-inner .main-faq .video {
    flex: 0 0 100%;
    max-width: 50%;
  }
}
