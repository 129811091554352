.client-section {
  background-color: var(--white);
  overflow: hidden;
  margin: 31rem 0 8rem;
  padding: 1rem 0;
  border-radius: 5rem;
}

.client-section .clients {
  justify-content: center;
  width: 85%;
  margin: 0 auto;
  display: flex;
}

.client-section .clients a {
  margin: 2rem;
}

.client-section .sub-heading {
  padding: 2rem 0;
  text-align: center;
}

.client-section .sub-heading h2 {
  /* background: transparent; */
  text-transform: uppercase;
  font-size: 2.7rem;
  color: var(--dark-blue);
  padding: 0.7rem 0;
  letter-spacing: 0.2rem;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.client-section svg {
  width: 8rem;
  height: 8rem;
  border-radius: 1.5rem;
}

.client-section a {
  filter: grayscale(100%);
  transition: 0.5s all ease;
}

.client-section a:hover {
  filter: grayscale(0%);
}
