/* header section */

/* main heading */

.main-heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.main-heading h1 {
  position: relative;
  margin-bottom: 0.3rem;
  color: var(--white);
}

.main-heading h1::before {
  content: "TIME\00a0IS\00a0MONEY";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(45deg, var(--light-blue), var(--medium-blue));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  animation: text 5s linear infinite;
}
.main-heading1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem;
}

.main-heading1 h1 {
  position: relative;
  margin-bottom: 0.3rem;
  color: var(--white);
}

.main-heading1 h1::before {
  content: "COMING\00a0SOON";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(45deg, var(--light-blue), var(--medium-blue));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  animation: text 5s linear infinite;
}

@keyframes text {
  0%,
  10%,
  100% {
    width: 0;
  }
  70%,
  90% {
    width: 100%;
  }
}

.main-heading h2 {
  font-weight: 400;
  color: var(--white);
}
.main-heading1 h2 {
  font-weight: 400;
  color: var(--white);
  line-height: 1.3;
}

.navlinks {
  max-width: 85%;
  margin: auto;
}

.nav-feature-1,
.nav-feature-2,
.nav-feature-3,
.nav-feature-4,
.nav-feature-5 {
  position: relative;
  transition: opacity 1s ease, transform 1s ease;
}

.nav-features {
  margin: 4rem 0rem 2rem;
  display: flex;
  flex-direction: column;
}

.nav-features div:nth-child(even) {
  align-self: flex-end;
}

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 1100px) {
}
@media screen and (max-width: 980px) {
  .main-heading h1 {
    font-size: 3.5rem;
  }
  .main-heading1 h1 {
    font-size: 3.5rem;
  }
  .main-heading1 h2 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 768px) {
  .main-heading h1 {
    font-size: 3.5rem;
  }
  .main-heading h2 {
    font-size: 1.7rem;
  }
  .main-heading1 h1 {
    font-size: 3.5rem;
  }
  .main-heading1 h2 {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 600px) {
  .main-heading1 h1 {
    font-size: 3rem;
  }
  .main-heading1 h2 {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 545px) {
  .main-heading h1 {
    font-size: 3rem;
  }
  .main-heading h2 {
    font-size: 1.5rem;
  }
  .main-heading1 h1 {
    font-size: 2rem;
  }
  .main-heading1 h2 {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 520px) {
  .nav-features {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .nav-features div:nth-child(even) {
    align-self: center;
  }

  .main-heading1 h1 {
    font-size: 2rem;
  }
  .main-heading1 h2 {
    font-size: 1rem;
  }
}
@media screen and (max-width: 480px) {
  .main-heading h1 {
    font-size: 2.5rem;
  }
  .main-heading h2 {
    font-size: 1rem;
  }
  .nav-features {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 400px) {
  .main-heading h1 {
    font-size: 2rem;
  }
  .main-heading h2 {
    font-size: 1rem;
  }
}
