.friend-section .counter-section-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.friend-section .counter-section-inner .break {
  display: none;
}

.friend-section .counter-section-inner:hover .link-img {
  transform: scale(1.2);
}

.friend-section .counter-section-inner:hover stop:first-child {
  stop-color: var(--medium-blue);
  transition: 0.5s all ease;
}

.friend-section .counter-section-inner:hover stop:last-child {
  stop-color: var(--dark-blue);
  transition: 0.5s all ease;
}

.friend-section .counter-section-inner .link-img {
  width: 8rem;
  height: 8rem;
  margin-bottom: 2rem;
  transition: 0.5s all ease;
}

.friend-section .counter-section-inner h2 {
  color: var(--dark-blue);
  margin-bottom: 0.7rem;
}

.friend-section .counter-section-inner h3 {
  color: var(--medium-blue);
  font-size: 2rem;
}

.friend-section .friend-counter-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 3rem 0rem;
  padding-bottom: 5rem;
  position: relative;
  transition: opacity 1s ease, transform 1s ease;
}

.friend-section {
  background-color: var(--white);
  /* background-attachment: fixed; */
  /* border-radius: 4rem; */
  margin: 8rem 0 31rem;
}

.friend-section .sub-heading {
  padding: 2rem 0;
  text-align: center;
}

.friend-section .sub-heading h2 {
  /* background: transparent; */
  text-transform: uppercase;
  font-size: 2.7rem;
  color: var(--dark-blue);
  padding: 0.7rem 0;
  letter-spacing: 0.2rem;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.friend-section .sub-heading h3 {
  color: var(--medium-blue);
  letter-spacing: 0.2rem;
  font-size: 3rem;
  transition: opacity 1s ease, transform 1s ease;
}

@media screen and (max-width: 950px) {
  .friend-section {
    margin-bottom: 25rem;
  }
}

@media screen and (max-width: 880px) {
  .friend-section .counter-section-inner .link-img {
    width: 6rem;
    height: 6rem;
  }
  .friend-section .sub-heading h2 {
    font-size: 2rem;
  }
  .friend-section .sub-heading h3 {
    font-size: 2rem;
  }
  .friend-section .counter-section-inner h2 {
    font-size: 1.5rem;
  }
  .friend-section .counter-section-inner h3 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 820px) {
  .friend-section {
    margin-bottom: 20rem;
  }
}
@media screen and (max-width: 730px) {
  .friend-section {
    margin-bottom: 15rem;
  }
  .friend-section .counter-section-inner .link-img {
    width: 4rem;
    height: 4rem;
  }
  .friend-section .sub-heading h2 {
    font-size: 1.8rem;
  }
  .friend-section .sub-heading h3 {
    font-size: 1.8rem;
  }
  .friend-section .counter-section-inner h2 {
    font-size: 1.2rem;
  }
  .friend-section .counter-section-inner h3 {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 600px) {
  .friend-section .sub-heading h2,
  .friend-section .sub-heading h3 {
    letter-spacing: 0;
  }
}
@media screen and (max-width: 520px) {
  .friend-section .counter-section-inner .link-img {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
  }
  .friend-section .friend-counter-section {
    margin: 0;
    padding-bottom: 1.5rem;
  }
  .friend-section .sub-heading h2 {
    font-size: 1.2rem;
    letter-spacing: 0;
  }
  .friend-section .sub-heading h3 {
    font-size: 1.5rem;
    margin-bottom: 0;
    letter-spacing: 0;
  }
  .friend-section .counter-section-inner h2 {
    font-size: 1rem;
  }
  .friend-section .counter-section-inner h3 {
    font-size: 1rem;
  }
  .friend-section .sub-heading {
    padding-top: 2rem;
  }
  .friend-section {
    margin-bottom: 10rem;
  }
}

@media screen and (max-width: 420px) {
  .friend-section .counter-section-inner .break {
    display: block;
  }
  .friend-section .counter-section-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
}
