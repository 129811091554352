.terms-section {
  background-color: var(--white);
  margin: 1rem 0 8rem;
}

.terms-section .terms-section-inner {
  margin: 0 10rem;
  max-width: 85%;
}

.terms-section .terms-section-inner .sub-heading {
  padding: 2rem 0;
  /* max-width: 85%; */
  margin: auto;
  text-align: center;
}

.terms-section .terms-section-inner .sub-heading h2 {
  text-transform: uppercase;
  font-size: 2.7rem;
  color: var(--dark-blue);
  margin-bottom: 1rem;
  padding: 0.7rem 0;
  letter-spacing: 0.2rem;
}

.terms-section .terms-section-inner .details b {
  font-size: 1.3rem;
}

.terms-section .terms-section-inner .details {
  user-select: none;
}

.terms-section .terms-section-inner .details li {
  list-style-type: disc;
  margin-left: 1rem;
}

.terms-section .terms-section-inner .details p {
  font-size: 1.3rem;
  line-height: 1.7rem;
  padding: 1rem 0;
  text-align: justify;
}

.terms-section .terms-section-inner .details h3 {
  padding: 1rem 0;
  color: var(--medium-blue);
}

.terms-section .terms-section-inner .details .highlight {
  background-color: yellow;
}

.terms-section .terms-section-inner .details .link {
  color: var(--medium-blue);
  transition: 0.3s all ease;
}

.terms-section .terms-section-inner .details table {
  width: 100%;
}
.terms-section .terms-section-inner .details th {
  text-align: center;
}

.terms-section .terms-section-inner .details table,
.terms-section .terms-section-inner .details th,
.terms-section .terms-section-inner .details td {
  border: 2px solid var(--dark-blue);
  border-collapse: collapse;
  padding: 1rem;
}

.terms-section .terms-section-inner .details .left {
  text-align: left;
}

.terms-section .terms-section-inner .details .link:hover {
  color: var(--dark-blue);
}
