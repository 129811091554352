.feature-section {
  background-color: var(--white);
  margin: 1rem 0 31rem;
  padding-bottom: 2rem;
}

.feature-section .feature {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
}
.feature-section .feature .feature-inner {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.feature-section .feature-box {
  max-width: 33%;
  flex: 0 0 33%;
}

.feature-section .feature .feature-inner .feature-inner-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  margin-bottom: 2rem;
  /* transition: opacity 1s ease, transform 1s ease; */
}

.feature-section .feature .title {
  text-align: center;
  color: var(--medium-blue);
  margin: 1.3rem;
}

.feature-section .feature .title h2 {
  font-size: 1.5rem;
}

.feature-section .sub-heading {
  padding: 2rem 0;
  max-width: 85%;
  margin: auto;
}

.feature-section .sub-heading h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 2.7rem;
  color: var(--dark-blue);
  padding: 0.7rem 0;
  margin-bottom: 1rem;
  letter-spacing: 0.2rem;
}

.feature-section .ih-item img {
  width: 100%;
  height: 100%;
}

.feature-section .ih-item.square {
  cursor: pointer;
  position: relative;
  width: 300px;
  height: 200px;
  border: 0.5rem solid var(--white);
  border-radius: 1rem;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
}

.feature-section .ih-item.square .info {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  line-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.feature-section .ih-item.square.effect10 {
  overflow: hidden;
}
.feature-section .ih-item.square.effect10.colored .info {
  background: linear-gradient(45deg, var(--dark-blue), var(--medium-blue));
}
.feature-section .ih-item.square.effect10.colored .info h3 {
  background: rgba(12, 34, 52, 0.6);
}
.feature-section .ih-item.square.effect10 .img {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.feature-section .ih-item.square.effect10 .info {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.feature-section .ih-item.square.effect10 .info p {
  font-size: 1rem;
  position: relative;
  color: var(--white);
  padding: 1rem 1rem 1rem;
  text-align: center;
}
.feature-section .ih-item.square.effect10 .square-inner:hover .info {
  visibility: visible;
  opacity: 1;
}

.feature-section .ih-item.square.effect10.top_to_bottom .img {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.feature-section .ih-item.square.effect10.top_to_bottom .info {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.feature-section
  .ih-item.square.effect10.top_to_bottom
  .square-inner:hover
  .img {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
}
.feature-section
  .ih-item.square.effect10.top_to_bottom
  .square-inner:hover
  .info {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

@media screen and (max-width: 1100px) {
  .feature-section .feature-box {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .feature-section .feature .feature-inner .feature-inner-box {
    margin: 0 -4rem;
  }
}

@media screen and (max-width: 950px) {
  .feature-section {
    margin-bottom: 25rem;
  }
}

@media screen and (max-width: 880px) {
  .feature-section .sub-heading h2 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 820px) {
  .feature-section {
    margin-bottom: 20rem;
  }
  .feature-section .sub-heading h2 {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 800px) {
  .feature-section {
    margin-bottom: 20rem;
  }
  .feature-section .ih-item.square {
    width: 250px;
    height: 200px;
  }
  .feature-section .ih-item.square .info {
    line-height: 1.3rem;
  }
  .feature-section .feature .title h2 {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 770px) {
  .feature-section {
    margin-bottom: 20rem;
  }
  .feature-section .ih-item.square {
    width: 250px;
    height: 200px;
  }
  .feature-section .ih-item.square .info {
    line-height: 1.3rem;
  }

  .feature-section .feature .feature-inner .feature-inner-box {
    width: 20%;
    margin: 0 -2rem;
  }
}
@media screen and (max-width: 730px) {
  .feature-section {
    margin-bottom: 15rem;
  }
}
@media screen and (max-width: 600px) {
  .feature-section .sub-heading h2 {
    letter-spacing: 0;
  }

  .feature-section .ih-item.square {
    width: 200px;
    height: 150px;
  }
  .feature-section .ih-item.square .info {
    line-height: 1.2rem;
  }

  .feature-section .ih-item.square.effect10 .info p {
    font-size: 10px;
  }

  .feature-section .feature .feature-inner .feature-inner-box {
    width: 20%;
    margin: 0 -2rem;
  }
  .feature-section .feature .title h2 {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 520px) {
  .feature-section .sub-heading h2 {
    font-size: 1.2rem;
    letter-spacing: 0;
    margin-bottom: 0;
  }

  .feature-section .sub-heading {
    padding-top: 2rem;
  }
  .feature-section {
    margin-bottom: 10rem;
  }
}

@media screen and (max-width: 500px) {
  .feature-section .ih-item.square {
    width: 250px;
    height: 200px;
  }

  .feature-section .ih-item.square .info {
    line-height: 1.3rem;
  }

  .feature-section .ih-item.square.effect10 .info p {
    font-size: 1rem;
  }

  .feature-section .feature .title h2 {
    font-size: 1.2rem;
  }

  .feature-section .feature .feature-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .feature-section .feature-box {
    max-width: 100%;
    flex: 1;
  }
  .feature-section .feature .feature-inner .feature-inner-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
  }
}
