.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  max-width: 85%;
  margin: auto;
  /* position: sticky;
  position: -webkit-sticky;
  top: 0; */
  z-index: 100;
}
.header .downloadApp2,
.header .downloadApp3 {
  display: none;
}

.header .nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.logo-img {
  width: 80px;
  display: none;
}

.logo-img-text {
  width: 225px;
}

.nav-links .links {
  border-radius: 1.5rem;
  font-size: 1.2rem;
  padding: 1rem;
  color: var(--light-blue);
  transition: all 0.5s ease;
}

.nav-links .links:hover,
.nav-links-responsive .links:hover {
  color: var(--white);
  background: linear-gradient(45deg, var(--dark-blue), var(--medium-blue));
  /* box-shadow: 0 5px 20px var(--medium-blue); */
}

.header .nav-btn {
  position: relative;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  align-items: center;
}

.nav-btn .btn {
  width: 2rem;
  height: 3px;
  background: var(--white);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  background: linear-gradient(45deg, var(--light-blue), var(--medium-blue));
  z-index: 100;
}

.nav-btn .btn::before,
.nav-btn .btn::after {
  content: "";
  position: absolute;
  width: 2rem;
  height: 3px;
  background: var(--white);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  background: linear-gradient(45deg, var(--light-blue), var(--medium-blue));
}

.nav-btn .btn::before {
  transform: translateY(-0.6rem);
}
.nav-btn .btn::after {
  transform: translateY(0.6rem);
}

.nav-btn.active .btn {
  transform: translateX(-50px);
  background: transparent;
}

.nav-btn.active .btn::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.nav-btn.active .btn::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

.header .nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(800px);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  /* overflow: hidden; */
}

.header .nav-links.active {
  transform: translateX(0);
  opacity: 1;
}

.header .nav-links-responsive {
  padding-top: 6rem;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  flex-direction: column;
  position: fixed;
  background-color: #000;
  height: 100%;
  width: 0;
  top: 0;
  right: -100px;
  z-index: 100;
  visibility: hidden;
  /* overflow: hidden; */
}

.header .nav-links-responsive.active {
  width: 50%;
  opacity: 1;
  right: 0;
}

.header .nav-btn-responsive {
  position: relative;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  align-items: center;
  z-index: 100;
  visibility: hidden;
}

.header .nav-btn-responsive.active {
  position: fixed;
  right: 10%;
  top: 40;
}

.nav-links-responsive .links {
  border-radius: 1.5rem;
  font-size: 1.2rem;
  padding: 1rem;
  color: var(--light-blue);
  transition: all 0.5s ease;
  margin: 1rem 0;
}

.nav-btn-responsive .btn {
  width: 2rem;
  height: 3px;
  background: var(--white);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  background: linear-gradient(45deg, var(--light-blue), var(--medium-blue));
  z-index: 100;
}

.nav-btn-responsive .btn::before,
.nav-btn-responsive .btn::after {
  content: "";
  position: absolute;
  width: 2rem;
  height: 3px;
  background: var(--white);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
  background: linear-gradient(45deg, var(--light-blue), var(--medium-blue));
}

.nav-btn-responsive .btn::before {
  transform: translateY(-0.6rem);
}
.nav-btn-responsive .btn::after {
  transform: translateY(0.6rem);
}

.nav-btn-responsive.active .btn {
  transform: translateX(-50px);
  background: transparent;
}

.nav-btn-responsive.active .btn::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.nav-btn-responsive.active .btn::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

@media screen and (max-width: 1000px) {
  .header .downloadApp1,
  .header .downloadApp3 {
    display: none;
  }
  .header .downloadApp2 {
    display: block;
  }
  .header .nav-links,
  .header .nav-btn {
    display: none;
  }

  .header .nav-links-responsive,
  .header .nav-btn-responsive {
    visibility: visible;
    transition: all 0.5s ease-in-out;
  }
}
@media screen and (max-width: 768px) {
  .header .downloadApp1,
  .header .downloadApp2,
  .header .logo-img-text {
    display: none;
  }

  .header .downloadApp3 {
    display: block;
  }
  .header .logo-img {
    display: block;
  }
  .header .nav-links-responsive.active {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .logo-img {
    width: 60px;
  }
}
