.privacy-section {
  background-color: var(--white);
  margin: 1rem 0 8rem;
}

.privacy-section .privacy-section-inner {
  margin: 0 5rem;
  max-width: 90%;
}

.privacy-section .privacy-section-inner .sub-heading {
  padding: 2rem 0;
  /* max-width: 85%; */
  margin: auto;
  text-align: center;
}

.privacy-section .privacy-section-inner .sub-heading h2 {
  text-transform: uppercase;
  font-size: 2.7rem;
  color: var(--dark-blue);
  margin-bottom: 1rem;
  padding: 0.7rem 0;
  letter-spacing: 0.2rem;
}

.privacy-section .privacy-section-inner .details b {
  font-size: 1.3rem;
}

.privacy-section .privacy-section-inner .details {
  user-select: none;
}

.privacy-section .privacy-section-inner .details li {
  list-style-type: disc;
  margin-left: 1rem;
}

.privacy-section .privacy-section-inner .details p {
  font-size: 1.3rem;
  line-height: 1.7rem;
  padding: 1rem 0;
  text-align: justify;
}

.privacy-section .privacy-section-inner .details h3 {
  padding: 1rem 0;
  color: var(--medium-blue);
}

.privacy-section .privacy-section-inner .details .link {
  color: var(--medium-blue);
  transition: 0.3s all ease;
}

.privacy-section .privacy-section-inner .details .link:hover {
  color: var(--dark-blue);
}
@media screen and (max-width: 1250px) {
  .privacy-section .privacy-section-inner {
    max-width: 90%;
  }
}

@media screen and (max-width: 1100px) {
  .privacy-section .privacy-section-inner .details p {
    padding: 2rem;
  }
}
@media screen and (max-width: 950px) {
  .privacy-section .privacy-section-inner {
    max-width: 100%;
    padding: 0 1rem 1rem;
  }

  .privacy-section .privacy-section-inner .details p {
    padding: 1.5rem 1rem;
  }
}

@media screen and (max-width: 880px) {
  .privacy-section .privacy-section-inner .sub-heading h2 {
    font-size: 2rem;
  }

  .privacy-section .privacy-section-inner .details p {
    padding: 1rem 0;
  }
}
@media screen and (max-width: 820px) {
  .privacy-section .privacy-section-inner .sub-heading h2 {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 800px) {
  .privacy-section .inner {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .privacy-section .privacy-section-inner {
    margin: 0 2rem;
  }
}
@media screen and (max-width: 730px) {
  .privacy-section .privacy-section-inner .sub-heading {
    padding-top: 0;
  }
  .privacy-section .privacy-section-inner {
    max-width: 100%;
    padding: 1rem;
  }
  .privacy-section .privacy-section-inner .btn {
    width: 25vw;
  }
}
@media screen and (max-width: 600px) {
  .privacy-section .privacy-section-inner h3 {
    letter-spacing: 0;
    font-size: 1.1rem;
  }

  .privacy-section .privacy-section-inner .sub-heading h2 {
    letter-spacing: 0;
  }

  .privacy-section .privacy-section-inner .btn {
    width: 30vw;
  }
}
@media screen and (max-width: 520px) {
  .privacy-section .privacy-section-inner h3 {
    font-size: 1rem;
    line-height: 1.5;
  }

  .privacy-section .privacy-section-inner .details p,
  .privacy-section .privacy-section-inner .details b {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }

  .privacy-section .privacy-section-inner .sub-heading h2 {
    font-size: 1.2rem;
  }
}
