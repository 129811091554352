.where-we-are .sub-heading {
  padding: 2rem 0;
  max-width: 85%;
  margin: auto;
}

.where-we-are {
  background-color: var(--white);
  margin: 1rem 0 8rem;
  padding: 0 0 3rem;
  text-align: center;
}

.where-we-are .sub-heading h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 2.7rem;
  color: var(--dark-blue);
  padding: 0.7rem 0;
  margin-bottom: 1rem;
  letter-spacing: 0.2rem;
}

.where-we-are .sub-heading .main-info {
  text-align: center;
  width: 75%;
  margin: auto;
}

.where-we-are .sub-heading .main-info p {
  font-size: 1.3rem;
  line-height: 1.5rem;
  text-align: justify;
}

.where-we-are .main-info-inner {
  display: flex;
  margin: 0 5rem;
  align-items: center;
  justify-content: space-between;
}

.where-we-are .main-info-inner .main-map {
  margin-right: 2rem;
}

.where-we-are .main-info-inner .customer-support h2 {
  font-size: 2rem;
  color: var(--medium-blue);
  padding: 0rem 0rem 2rem;
}

.where-we-are .main-info-inner .customer-support .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
}

.where-we-are .main-info-inner .customer-support .info .icon {
  margin-right: 1.5rem;
  width: 3rem;
}

.where-we-are .main-info-inner .customer-support .info .icon img {
  width: 100%;
  height: 100%;
}

.where-we-are .main-info-inner .customer-support .info .text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.where-we-are .main-info-inner .customer-support .info .text p {
  font-size: 1.3rem;
  padding: 0.5rem;
}

.where-we-are .main-info-inner .customer-support .info .text p a {
  color: var(--dark-blue);
  transition: 0.3s all;
}

.where-we-are .main-info-inner .customer-support .info .text p a:hover {
  color: var(--medium-blue);
}
.where-we-are .btn {
  width: 20vw;
  margin: auto;
  margin-top: 2rem;
}

@media screen and (max-width: 1250px) {
  .main-info-inner {
    margin: 0 5rem;
  }
  .where-we-are .main-info-inner .customer-support .info .text p {
    font-size: 1.3rem;
    padding: 0.1rem;
    line-height: 1.5;
  }
}
@media screen and (max-width: 950px) {
  .where-we-are {
    margin-bottom: 25rem;
  }
  .where-we-are .btn {
    width: 20vw;
  }
  .where-we-are .main-info-inner {
    flex-direction: column;
  }
  .where-we-are .main-info-inner .main-map {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 880px) {
  .where-we-are .sub-heading h2 {
    font-size: 2rem;
  }

  .where-we-are .customer-support h2 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 820px) {
  .where-we-are {
    margin-bottom: 20rem;
  }
  .where-we-are .btn {
    width: 25vw;
  }
}
@media screen and (max-width: 730px) {
  .where-we-are {
    margin-bottom: 15rem;
  }

  .where-we-are .sub-heading h2 {
    font-size: 1.8rem;
  }

  .where-we-are .main-info-inner .customer-support h2 {
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }
  .where-we-are .main-info-inner .customer-support .info .text p {
    font-size: 1.2rem;
    padding: 0.1rem;
    line-height: 1.3;
  }
  .where-we-are .sub-heading .main-info {
    width: 85%;
  }
}
@media screen and (max-width: 645px) {
  .where-we-are .sub-heading h2 {
    letter-spacing: 0;
  }
  .where-we-are .main-info-inner .customer-support .info .text p {
    font-size: 1rem;
  }
  .where-we-are .main-info-inner {
    margin: 0 3rem;
  }
  .where-we-are .btn {
    width: 30vw;
  }
}
@media screen and (max-width: 520px) {
  .where-we-are .main-info-inner {
    margin: 0;
    padding-bottom: 1.5rem;
  }
  .where-we-are .sub-heading h2 {
    font-size: 1.2rem;
    letter-spacing: 0;
  }
  .where-we-are .sub-heading .main-info p {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
  .where-we-are .customer-support h2 {
    font-size: 1rem;
  }

  .where-we-are .sub-heading {
    padding-top: 2rem;
  }
  .where-we-are {
    margin-bottom: 10rem;
  }
  .where-we-are .btn {
    margin-top: 0;
    width: 35vw;
  }

  .where-we-are .main-info-inner .customer-support .info .icon {
    margin-right: 1.2rem;
    width: 2rem;
  }
}

@media screen and (max-width: 420px) {
  .where-we-are .customer-support {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .where-we-are .main-info-inner .customer-support .info .icon {
    margin-right: 1rem;
    width: 1.5rem;
  }
}

@media screen and (max-width: 420px) {
  .where-we-are .btn {
    width: 40vw;
  }
}
