.advertise-section {
  background-color: var(--white);
  margin: 1rem 0 31rem;
}

.advertise-section .advertise-section-inner {
  max-width: 85%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}

.advertise-section .advertise-section-info {
  transition: opacity 1s ease, transform 1s ease;
  margin: 2rem 1rem;
}

.advertise-section .advertise-section-info h3 {
  color: var(--medium-blue);
  font-size: 1.5rem;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.advertise-section .advertise-section-info p {
  font-size: 1.3rem;
  color: var(--dark-blue);
  text-align: justify;
  line-height: 1.5;
  margin-bottom: 5rem;
}

.advertise-section .advertise-video {
  height: 500px;
  width: 2500px;
}

.advertise-section .advertise-section-info .footer {
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.advertise-section .btn {
  width: 20vw;
}

@media screen and (max-width: 1250px) {
  .advertise-section .advertise-section-inner {
    max-width: 90%;
    padding: 1rem;
  }
}

@media screen and (max-width: 950px) {
  .advertise-section {
    margin-bottom: 25rem;
  }
  .advertise-section .btn {
    width: 30vw;
  }
  .advertise-section .advertise-section-info {
    padding: 0;
  }
  .advertise-section .advertise-section-inner {
    max-width: 100%;
    padding: 1rem;
  }
}

@media screen and (max-width: 880px) {
  .advertise-section .advertise-section-info h3 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 820px) {
  .advertise-section {
    margin-bottom: 20rem;
  }
}
@media screen and (max-width: 800px) {
  .advertise-section .advertise-section-inner {
    max-width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  .advertise-section .advertise-video {
    height: 500px;
    width: 500px;
  }
  .advertise-section .advertise-section-info p {
    margin-bottom: 2rem;
  }
  .advertise-section .advertise-section-info .footer {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 730px) {
  .advertise-section {
    margin-bottom: 15rem;
  }

  .advertise-section .advertise-section-info h3 {
    font-size: 1.5rem;
  }
  .advertise-section .btn {
    width: 40vw;
  }
  .advertise-section .advertise-section-inner {
    max-width: 100%;
    padding: 1.5rem;
  }
}
@media screen and (max-width: 600px) {
  .advertise-section .advertise-section-info h3 {
    letter-spacing: 0;
  }
  .advertise-section .advertise-video {
    height: 300px;
    width: 400px;
  }
}
@media screen and (max-width: 520px) {
  .advertise-section .advertise-section-info h3 {
    font-size: 1.2rem;
    letter-spacing: 0;
  }

  .advertise-section {
    margin-bottom: 10rem;
  }

  .advertise-section .advertise-section-info p {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
}

@media screen and (max-width: 420px) {
  .advertise-section .btn {
    width: 50vw;
  }
  .advertise-section .advertise-video {
    height: 300px;
    width: 350px;
  }
}
@media screen and (max-width: 350px) {
  .advertise-section .advertise-video {
    height: 300px;
    width: 300px;
  }
}
