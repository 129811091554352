.what-we-do {
  background-color: var(--white);
  margin: 1rem 0 31rem;
}

.what-we-do .bonus {
  text-align: center;
}
.what-we-do .counter-section-inner .break {
  display: none;
}

.what-we-do .bonus-inner {
  display: inline-block;
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1.5rem;
  color: var(--white);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  background: linear-gradient(45deg, var(--dark-blue), var(--medium-blue));
}

.what-we-do .sub-heading {
  padding: 2rem 0;
  text-align: center;
}

.what-we-do .sub-heading h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 2.7rem;
  color: var(--dark-blue);
  padding: 0.7rem 0;
  margin-bottom: 1rem;
  letter-spacing: 0.2rem;
}

.what-we-do .sub-heading .main-info {
  text-align: center;
  width: 75%;
  margin: auto;
}

.what-we-do .sub-heading .main-info p {
  font-size: 1.3rem;
  line-height: 1.5rem;
  text-align: justify;
}

.what-we-do .friend-counter-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 3rem 0rem 2rem;
  position: relative;
  transition: opacity 1s ease, transform 1s ease;
}

.what-we-do .counter-section-inner:hover .link-img {
  transform: scale(1.2);
}

.what-we-do .counter-section-inner:hover stop:first-child {
  stop-color: var(--medium-blue);
  transition: 0.5s all ease;
}

.what-we-do .counter-section-inner:hover stop:last-child {
  stop-color: var(--dark-blue);
  transition: 0.5s all ease;
}

.what-we-do .counter-section-inner {
  margin-bottom: 3rem;
  text-align: center;
}

.what-we-do .counter-section-inner .link-img {
  width: 8rem;
  height: 8rem;
  margin-bottom: 2rem;
  transition: 0.5s all ease;
}

.what-we-do .counter-section-inner h2 {
  color: var(--dark-blue);
  margin-bottom: 0.7rem;
}

.what-we-do .counter-section-inner h3 {
  color: var(--medium-blue);
  font-size: 2rem;
}

@media screen and (max-width: 950px) {
  .what-we-do {
    margin-bottom: 25rem;
  }
}

@media screen and (max-width: 880px) {
  .what-we-do .counter-section-inner .link-img {
    width: 6rem;
    height: 6rem;
  }
  .what-we-do .sub-heading h2 {
    font-size: 2rem;
  }

  .what-we-do .counter-section-inner h2 {
    font-size: 1.5rem;
  }
  .what-we-do .counter-section-inner h3 {
    font-size: 1.5rem;
  }
  .what-we-do .bonus-inner {
    text-align: center;
    margin-bottom: 4rem;
    font-size: 1.2rem;
    color: var(--white);
    padding: 1rem 2rem;
  }
}
@media screen and (max-width: 820px) {
  .what-we-do {
    margin-bottom: 20rem;
  }
}
@media screen and (max-width: 730px) {
  .what-we-do {
    margin-bottom: 15rem;
  }
  .what-we-do .counter-section-inner .link-img {
    width: 4rem;
    height: 4rem;
  }
  .what-we-do .sub-heading h2 {
    font-size: 1.8rem;
  }

  .what-we-do .counter-section-inner h2 {
    font-size: 1.2rem;
  }
  .what-we-do .counter-section-inner h3 {
    font-size: 1.2rem;
  }
  .what-we-do .bonus-inner {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 1.1rem;
    color: var(--white);
    padding: 0.8rem 1.5rem;
    line-height: 1.2;
  }
}
@media screen and (max-width: 600px) {
  .what-we-do .sub-heading h2 {
    letter-spacing: 0;
  }
  .what-we-do .bonus-inner {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 1.1rem;
    color: var(--white);
    padding: 0.6rem 1.2rem;
    line-height: 1.2;
  }
}
@media screen and (max-width: 520px) {
  .what-we-do .counter-section-inner .link-img {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
  }
  .what-we-do .rupee-counter-section {
    margin: 0;
    padding-bottom: 1.5rem;
  }
  .what-we-do .sub-heading h2 {
    font-size: 1.2rem;
    letter-spacing: 0;
  }

  .what-we-do .counter-section-inner h2 {
    font-size: 1rem;
  }
  .what-we-do .counter-section-inner h3 {
    font-size: 1rem;
  }
  .what-we-do .sub-heading {
    padding-top: 2rem;
  }
  .what-we-do {
    margin-bottom: 10rem;
  }
  .what-we-do .bonus-inner {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 1.1rem;
    color: var(--white);
    padding: 0.5rem 1rem;
    line-height: 1.2;
  }
  .what-we-do .sub-heading .main-info p {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
}

@media screen and (max-width: 420px) {
  .what-we-do .counter-section-inner .break {
    display: block;
  }
  .what-we-do .counter-section-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .what-we-do .bonus-inner {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 1rem;
    line-height: 1.2;
    color: var(--white);
    padding: 0.5rem 1rem;
  }
}
