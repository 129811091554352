.advertise-with-us {
  background-color: var(--white);
  margin: 1rem 0 8rem;
  padding-bottom: 2rem;
}

.advertise-with-us .advertise-with-us-inner {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  margin: 0 5rem;
}

.advertise-with-us .sub-heading {
  padding: 2rem 0;
  max-width: 85%;
  margin: auto;
}

.advertise-with-us .sub-heading h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 2.7rem;
  color: var(--dark-blue);
  margin-bottom: 1rem;
  padding: 0.7rem 0;
  letter-spacing: 0.2rem;
}

.advertise-with-us .inner {
  flex: 0 0 50%;
  max-width: 50%;
}

.advertise-with-us .inner h3 {
  color: var(--medium-blue);
  font-size: 1.5rem;
  margin: 1rem 0;
}

.advertise-with-us .inner p {
  font-size: 1.3rem;
  text-align: justify;
  line-height: 1.5;
  margin-bottom: 3rem;
}

.advertise-with-us .inner .email-link:hover a {
  color: var(--dark-blue);
  transition: 0.3s all ease;
}

@media screen and (max-width: 1250px) {
  .advertise-with-us .advertise-with-us-inner {
    max-width: 90%;
    padding: 1rem;
  }
}

@media screen and (max-width: 950px) {
  .advertise-with-us {
    margin-bottom: 25rem;
  }

  .advertise-with-us .advertise-with-us-inner {
    max-width: 100%;
    padding: 1rem;
  }
}

@media screen and (max-width: 880px) {
  .advertise-with-us .sub-heading h2 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 820px) {
  .advertise-with-us {
    margin-bottom: 20rem;
  }
  .advertise-with-us .sub-heading h2 {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 800px) {
  .advertise-with-us .advertise-with-us-inner {
    max-width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  .advertise-with-us .video {
    height: 500px;
    width: 500px;
  }
  .advertise-with-us .advertise-with-us-inner p {
    margin-bottom: 2rem;
  }

  .advertise-with-us .inner {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .advertise-with-us .sub-heading {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 730px) {
  .advertise-with-us {
    margin-bottom: 15rem;
  }

  .advertise-with-us .advertise-with-us-inner h3 {
    font-size: 1.5rem;
  }

  .advertise-with-us .advertise-with-us-inner {
    max-width: 100%;
    padding: 2rem;
  }
}
@media screen and (max-width: 600px) {
  .advertise-with-us .advertise-with-us-inner h3 {
    letter-spacing: 0;
  }
  .advertise-with-us .video {
    height: 300px;
    width: 400px;
  }
  .advertise-with-us .sub-heading h2 {
    letter-spacing: 0;
  }
}
@media screen and (max-width: 520px) {
  .advertise-with-us .advertise-with-us-inner h3 {
    font-size: 1.2rem;
    letter-spacing: 0;
  }

  .advertise-with-us {
    margin-bottom: 10rem;
  }

  .advertise-with-us .advertise-with-us-inner p {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
  .advertise-with-us .sub-heading h2 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 420px) {
  .advertise-with-us .video {
    height: 300px;
    width: 350px;
    padding: 0 1rem;
  }
}
@media screen and (max-width: 350px) {
  .advertise-with-us .video {
    height: 300px;
    width: 300px;
  }
}
