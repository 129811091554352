.copyright {
  text-align: center;
}

.copyright-text {
  width: 85%;
  display: flex;
  margin: auto;
  justify-content: space-between;
  color: var(--medium-blue);
  padding: 1rem;
}
.company-links {
  margin-right: 0.4rem;
}

.company-links .link {
  color: var(--dark-blue);
  padding: 0 0.9rem;
  transition: 0.3s all ease;
}

.company-links .link:hover {
  color: var(--medium-blue);
}

.border {
  border-top: 1px solid var(--dark-blue);
  margin: 0rem auto;
  width: 95%;
}

.footer {
  background-color: var(--white);
}

.logo {
  width: 200px;
}
.logo2 {
  display: none;
}

.logo img {
  width: 100%;
  height: 100%;
}

.footer-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 85%;
  margin: auto;
  position: relative;
  padding: 1.5rem;
  flex-wrap: wrap;
}

.social-icon {
  text-align: center;
}

.social-icon a {
  border: 2px solid var(--medium-blue);
  display: inline-block;
  height: 40px;
  width: 40px;
  font-size: 20px;
  text-align: center;
  color: var(--medium-blue);
  margin: 0 0.5rem;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.social-icon a i {
  line-height: 36px;
}

.social-icon a:hover {
  color: var(--white);
  background: linear-gradient(45deg, var(--dark-blue), var(--medium-blue));
}

.db2 {
  display: none;
}

@media screen and (max-width: 980px) {
  .db1 {
    display: none;
  }
  .db2 {
    display: block;
  }
}

@media screen and (max-width: 850px) {
  .footer-section {
    justify-content: center;
  }
  .footer-section .logo {
    margin-right: 8rem;
  }
  .social-icon {
    margin-left: 3rem;
  }
  .social-icon {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .footer-section {
    max-width: 90%;
  }
}
@media screen and (max-width: 650px) {
  .db1 {
    display: none;
  }
  .footer-section .logo {
    width: 200px;
    margin-right: 2rem;
  }
  .footer-section .logo2 {
    display: none;
  }

  .social-icon {
    margin-top: 1rem;
  }
  .copyright-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 552px) {
  .copyright-text .company-links {
    margin-bottom: 1rem;
  }
  .copyright-text .text {
    order: 2;
  }
  .social-icon {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 520px) {
  .footer-section :nth-child(3) {
    order: 2;
  }
  .footer-section .logo {
    width: 150px;
    margin-right: 2rem;
  }
}
@media screen and (max-width: 500px) {
  /* .footer-section .download-btn {
    order: 2;
    margin-top: 1rem;
  } */

  .footer-section {
    width: 100%;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .social-icon {
    margin-left: 0;
  }
}
@media screen and (max-width: 420px) {
  .footer-section {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .copyright-text .text {
    font-size: 14px;
  }
}

@media screen and (max-width: 390px) {
  .footer-section .logo {
    width: 130px;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 350px) {
  .footer-section {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
