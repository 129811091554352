.login {
  display: grid;
  height: 100%;
  width: 100%;
  place-items: center;
  overflow: hidden;
}

.wrapper {
  overflow: hidden;
  min-width: 350px;
  width: 20%;
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper h2 {
  color: var(--medium-blue);
}

.wrapper .slide-controls {
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  margin: 30px 0 10px 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 15px;
}
.slide-controls .slide {
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}
.slide-controls label.signup {
  color: #000;
}
.slide-controls .slider-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 15px;
  background: -webkit-linear-gradient(
    left,
    var(--dark-blue),
    var(--medium-blue)
  );
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
input[type="radio"] {
  display: none;
}

#signup:checked ~ .slider-tab {
  left: 50%;
}
#signup:checked ~ label.signup {
  color: #fff;
  cursor: default;
  user-select: none;
}
#signup:checked ~ label.login {
  color: #000;
}
#login:checked ~ label.signup {
  color: #000;
}
#login:checked ~ label.login {
  cursor: default;
  user-select: none;
}
.wrapper .login-form,
.wrapper .signup-form {
  width: 100%;
  margin-top: 1rem;
  transition: all 0.3s ease;
}

.wrapper .signup-form {
  display: none;
}
.wrapper .login-form {
  display: block;
}

.wrapper .form-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
}
.wrapper input {
  height: 50px;
  margin-top: 20;
  width: 100%;
  outline: none;
  padding-left: 15px;
  border-radius: 15px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 17px;
  transition: all 0.3s ease;
}

.wrapper input::placeholder {
  color: #999;
  transition: all 0.3s ease;
}

.wrapper .submitBtn {
  width: 100%;
  z-index: 1;
  position: relative;
  background: -webkit-linear-gradient(
    right,
    var(--dark-blue),
    var(--medium-blue)
  );
  border: none;
  color: #fff;
  padding-left: 0;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  height: 50px;
  margin-top: 1.5rem;
  transition: all 1s ease;
}

.wrapper .submitBtn:hover {
  background: -webkit-linear-gradient(
    left,
    var(--dark-blue),
    var(--medium-blue)
  );
}

.wrapper .forgot-link {
  text-decoration: none;
  color: var(--medium-blue);
}

.wrapper .forgot-link:hover {
  text-decoration: underline;
  transition: all 0.3s ease;
}

.wrapper .error-msg {
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 5px;
  color: red;
}
