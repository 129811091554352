.link-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.8rem 2rem;
  width: 30vw;
  height: 15vh;
  background: var(--white);
  border-radius: 2rem;
  margin: 1rem 3rem 1rem;
  color: var(--dark-blue);
  position: relative;
  transition: 0.6s all ease;
  z-index: 3;
}

.link-card svg {
  fill: var(--dark-blue);
}

.link-card:hover {
  color: var(--white);
}

.link-card:hover svg {
  fill: var(--white);
}

.link-card::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  background: linear-gradient(45deg, var(--dark-blue), var(--medium-blue));
  transition: all 0.5s;
  z-index: -1;
  border-radius: 2rem;
}

.link-card:hover::after {
  width: 100%;
  height: 100%;
}

.link-card .link-img {
  width: 4rem;
  height: 4rem;
}

.link-card .link-title {
  font-size: 1.7rem;
}

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 1100px) {
  .link-card {
    padding: 0.5rem 1.5rem;
    width: 35vw;
  }
}
@media screen and (max-width: 980px) {
  .link-card {
    padding: 0.3rem 1.2rem;
    width: 40vw;
  }
}
@media screen and (max-width: 768px) {
  .link-card {
    padding: 0.2rem 1rem;
    width: 50vw;
  }
  .link-card .link-title {
    font-size: 1.5rem;
  }
  .link-card .link-img {
    width: 3rem;
    height: 3rem;
  }
}

@media screen and (max-width: 520px) {
  .link-card {
    width: 70vw;
  }
}
@media screen and (max-width: 480px) {
  .link-card {
    padding: 0rem 1rem;
    height: 13vh;
  }
}
