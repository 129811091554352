.dark {
  color: var(--light-blue);
  border: 0.1rem solid var(--light-blue);
}
.light {
  color: var(--dark-blue);
  border: 0.1rem solid var(--dark-blue);
}

.downloadlink {
  /* width: 20rem; */
  padding: 1rem;
  border-radius: 0.6rem;
  text-align: center;
  font-size: 1.4rem;
  text-decoration: none;
  position: relative;
  z-index: 3;
}

.downloadlink:hover {
  color: var(--white);
}

.downloadlink::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(45deg, var(--dark-blue), var(--medium-blue));
  z-index: -1;
  border-radius: 0.4rem;
  transition: all 0.5s;
}

.downloadlink:hover::before {
  width: 100%;
  height: 100%;
}

.cookie-bar {
  width: 100%;
  height: auto;
  position: fixed;
  background-color: black;
  color: var(--white);
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 9rem;
  z-index: 10;
  font-size: 1.3rem;
}

.cookie-bar p {
  font-size: 1rem;
}

.cookie-btn {
  padding: 0.5rem 1.5rem;
  border-radius: 0.6rem;
  text-align: center;
  font-size: 1.4rem;
  text-decoration: none;
  position: relative;
  z-index: 3;
}

.cookie-btn:hover {
  color: var(--white);
}

.cookie-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(45deg, var(--dark-blue), var(--medium-blue));
  z-index: -1;
  border-radius: 0.4rem;
  transition: all 0.5s;
}

.cookie-btn:hover::before {
  width: 100%;
  height: 100%;
}

.cookie-bar button {
  cursor: pointer;
  background-color: transparent;
}

@media (max-width: 900px) {
  .cookie-bar {
    margin: 0;
    padding: 0.5rem 1rem;
  }
}
@media (max-width: 768px) {
  .cookie-btn {
    padding: 0.5rem 1rem;
    text-align: center;
    font-size: 1rem;
    text-decoration: none;
    position: relative;
    z-index: 3;
  }
}
@media (max-width: 480px) {
  .downloadlink {
    /* width: 20rem; */
    padding: 1rem;
    border-radius: 0.6rem;
    text-align: center;
    font-size: 1rem;
    text-decoration: none;
    position: relative;
    z-index: 3;
  }
}
